/* eslint-disable react/no-array-index-key */
import React from 'react';
import AuthService from '../services/AuthService';

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();

  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.email}</strong>
          {' '}
          Profile
        </h3>
      </header>
      <p>
        <strong>Token:</strong>
        {' '}
        {currentUser.accessToken.substring(0, 20)}
        {' '}
        ...
        {' '}
        {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
      </p>
      <p>
        <strong>Id:</strong>
        {' '}
        {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong>
        {' '}
        {currentUser.email}
      </p>
      <strong>Admin:</strong>
      <ul>
        { currentUser.admin === true ? 'Admin' : 'false'}
      </ul>
    </div>
  );
};

export default Profile;
