import axios from 'axios';
import authHeader from './AuthHeader';
import AuthService from './AuthService';

const API_URL = process.env.REACT_APP_API_URL;

const getPublicContent = () => axios.get(`${API_URL}version`);

const getUserBoard = () => axios.get(`${API_URL}users/${AuthService.getCurrentUser().id}`, { headers: authHeader() });
const getAdminBoard = () => axios.get(`${API_URL}genders`, { headers: authHeader() });

export default {
  getPublicContent,
  getUserBoard,
  getAdminBoard,
};
